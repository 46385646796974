import React, { useEffect } from 'react'
import * as S from './styles'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { Button } from '@interco/inter-ui/components/Button'
import { initTheme, Theme } from '@interco/inter-ui'

import { tagBottomSheetClick } from '../../tagHelpers/_index'

export type Investment = {
  title: string;
  body: string;
  detailsList: {
    title: string;
    body: string;
    icon: string;
  }[];
  ctaText: string;
  ctaLink: string;
  adjustLink: string;
}

type InvestmentsDisplayProps = {
  investment: Investment;
}

const InvestmentsDisplay: React.FC<InvestmentsDisplayProps> = ({ investment }: InvestmentsDisplayProps) => {
  useEffect(() => {
    initTheme(Theme.PF)
  })

  const handleClick = async (investment: Investment) => {
    tagBottomSheetClick(investment)
  }

  return (
    <S.InvestmentsDisplay>
      <h1 className='investments__title'>{investment.title}</h1>
      <p className='investments__body'>{investment.body}</p>
      <h2 className='investments-list__title'>Você pode usar para:</h2>
      <ul className='investments-list'>
        {investment.detailsList.map((item: { title: string; body: string; icon: string }, index: number) => (
          <li key={index} className='investments-list__item'>
            <IconsSwitch lib='interco' icon={item.icon} width='24' height='24' color='' />
            <div>
              <h3 className='investments-list__item__title'>{item.title}</h3>
              <p className='investments-list__item__body'>{item.body}</p>
            </div>
          </li>
        ))}
      </ul>
      <a className='investments__link' onClick={() => handleClick(investment)} href={investment.adjustLink}>
        <Button
          fullWidth
          type='button'
        >
          {investment.ctaText}
        </Button>
      </a>

    </S.InvestmentsDisplay>
  )
}

export default InvestmentsDisplay
