import React, { useEffect } from "react"
import Picture from "src/components/Picture"
import CardWithButton from "./components/CardWithButton/_CardWithButton"
import * as S from "./styles"

import { initTheme, Theme } from "@interco/inter-ui"
import { BottomSheet } from "@interco/inter-ui/components/BottomSheet"
import { Button } from "@interco/inter-ui/components/Button"
import IconsSwitch from "src/components/IconsSwitch/_index"
import YoutubeVideo from "src/components/YoutubeVideo"
import { primary } from "src/styles/newColors"
import {
  TEXT_CONTENT,
} from "./_constants"
import { MenuOption } from "./_types"
import InvestmentsBottomSheet from "./components/InvestmentsDisplay/_InvestmentsDisplay"
import TesouroMePoupeAppHeader from "./components/TesouroMePoupeAppHeader/_TesouroMePoupeAppHeader"
import TesouroMePoupeAppFAQ from "./FAQ/_TesouroMePoupeAppFAQ"
import { useInvestmentData } from "./hooks/_useInvestmentData"
import { usePageViewState } from "./hooks/_usePageViewState"

import { tagBackClick, tagCardClick, tagCtaClick, tagHelpClick, tagVideoClick } from "./tagHelpers/_index"

const TesouroMePoupe: React.FC = () => {
  const [ showBottomSheet, setShowBottomSheet ] = React.useState(false)
  const {
    data: selectedInvestment,
    setInvestment,
  }: ReturnType<typeof useInvestmentData> = useInvestmentData()

  const {
    viewState,
    openFaq,
    openVideo,
    returnHome,
  }: ReturnType<typeof usePageViewState> = usePageViewState()

  useEffect(() => {
    initTheme(Theme.PF)
  })

  const handleCardClick = async (menuOption: MenuOption) => {
    setInvestment(menuOption.fileToLoad)
    setShowBottomSheet(true)
    tagCardClick(menuOption.title)
  }

  const handleVideoClick = async () => {
    openVideo()
    tagVideoClick()
  }

  const handleCtaClick = async () => {
    tagCtaClick()
  }

  const handleHelpClick = async () => {
    openFaq()
    tagHelpClick()
  }

  const handleBackClick = async () => {
    returnHome()
    tagBackClick()
  }

  const verTodosUrl = "https://bancointer.go.link/investimentos/investir/tesouroDireto?adj_t=1gbp3obz&adj_adgroup=investimentos&adj_creative=bto_vertodostesouro"

  return (
    <>
      <noscript>
        <iframe
          title='google manager'
          src='https://www.googletagmanager.com/ns.html?id=GTM-KKPG'
          height='0'
          width='0'
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
      <S.MobileFrame>
        <TesouroMePoupeAppHeader
          showHelpBtn={!viewState.isVisibile}
          onHelpClick={handleHelpClick}
          onBackClick={handleBackClick}
        />
        <S.TesouroMePoupeAppSection
          id='webview-construa-seu-tesouro'
          isFaqVisible={viewState.isFaq}
          isVideoVisible={viewState.isVideo}
        >
          {viewState.isFaq && <TesouroMePoupeAppFAQ />}
          {viewState.isVideo && (
            <div className='video-embeded-frame'>
              <YoutubeVideo
                src='https://www.youtube.com/embed/zNGI1bEAAH4?si=SETmgXH5Y-iKHHHW'
                width='100%'
                className='my-auto'
                height='210px'
              />
            </div>
          )}
          {!viewState.isVisibile && (
            <>
              <div className='video-block' onClick={handleVideoClick}>
                <Picture
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-webview-new/image.webp'
                  minWidth={{ sm: '309px' }}
                  minHeight={{ sm: '123px' }}
                  imgClassName='mx-auto'
                  width='100%'
                  height='auto'
                />
              </div>

              <h1 className='title'>{TEXT_CONTENT.heading}</h1>

              <S.CardsGrid>
                {TEXT_CONTENT.menuOptions.map(
                (menuOption: MenuOption, index: number) => (
                  <li key={index}>
                    <CardWithButton
                      icon={menuOption.icon}
                      title={menuOption.title}
                      onButtonClick={() => {
                        handleCardClick(menuOption)
                      }}
                    />
                  </li>
                ),
              )}
              </S.CardsGrid>
              <a
                className='button-block'
                href={verTodosUrl}
              >
                <Button
                  fullWidth
                  variant='secondary'
                  icon={
                    <IconsSwitch
                      lib='interco'
                      icon='action-navigation/ic_chevron_right'
                      width='24'
                      height='24'
                      color={primary[500]}
                    />
                }
                  onClick={handleCtaClick}
                >
                  {TEXT_CONTENT.cta}
                </Button>
              </a>

              <S.BannerBox>
                <Picture
                  width='100%'
                  minHeight={{ sm: "109px" }}
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/webview-new-2/image.webp'
                  alt='Uma mãe e sua filha sentada em um sofá com um celular na mão'
                />
                <div className='banner-box__text'>
                  <h2
                    className='banner-box__title'
                    dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.bannerTitle }}
                  />
                  <p className='banner-box__body'>{TEXT_CONTENT.bannerBody}</p>
                </div>
              </S.BannerBox>
              {showBottomSheet && selectedInvestment && (
                <BottomSheet onClose={() => setShowBottomSheet(false)}>
                  <InvestmentsBottomSheet investment={selectedInvestment} />
                </BottomSheet>
            )}
            </>
        )}
        </S.TesouroMePoupeAppSection>
      </S.MobileFrame>
    </>
  )
}

export default TesouroMePoupe
